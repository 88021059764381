import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2505 3155 c0 -253 1 -355 2 -227 2 128 2 335 0 460 -1 125 -2 20 -2
-233z"/>
<path d="M1712 3173 c-47 -119 -172 -457 -172 -466 0 -27 21 18 74 163 32 85
61 161 65 168 4 7 35 -60 73 -160 l66 -173 51 -3 c34 -2 51 1 51 9 0 9 -116
322 -175 472 -10 24 -21 21 -33 -10z"/>
<path d="M2955 3190 c-33 -4 -104 -8 -157 -9 -96 -1 -128 -13 -68 -24 16 -4
36 -17 45 -31 20 -30 35 -164 35 -313 l0 -113 50 0 50 0 2 228 3 227 60 12
c82 17 220 17 277 -1 70 -21 131 -59 178 -113 65 -74 124 -202 139 -303 l6
-45 54 -3 53 -3 -7 43 c-19 111 -86 237 -170 321 -54 54 -161 109 -243 126
-58 12 -213 12 -307 1z"/>
<path d="M1673 2731 c-32 -27 -29 -37 3 -12 35 27 62 27 74 -1 10 -23 10 -23
7 2 -2 20 -8 25 -32 27 -18 2 -39 -5 -52 -16z"/>
<path d="M1248 2604 c-5 -4 -8 -36 -8 -71 l0 -63 43 0 c61 0 67 8 63 68 -2 29
-6 55 -9 58 -9 10 -82 16 -89 8z m78 -25 c9 -15 -6 -27 -40 -31 -24 -2 -30 1
-32 20 -3 19 0 22 30 22 19 0 38 -5 42 -11z m9 -59 c12 -19 -17 -41 -49 -38
-24 2 -31 8 -32 26 -1 19 4 22 37 22 21 0 41 -5 44 -10z"/>
<path d="M1455 2598 c-2 -7 -5 -39 -7 -71 l-3 -57 40 0 c58 0 85 22 85 70 0
34 -4 42 -30 55 -35 18 -79 20 -85 3z m87 -25 c10 -9 18 -24 18 -35 0 -28 -36
-58 -70 -58 -30 0 -30 1 -30 55 l0 55 32 0 c17 0 40 -7 50 -17z"/>
<path d="M2668 2604 c-5 -4 -8 -36 -8 -71 l0 -63 38 0 c55 0 82 23 82 69 0 21
-6 43 -13 49 -17 14 -90 26 -99 16z m87 -34 c35 -38 -3 -90 -66 -90 -16 0 -19
7 -19 55 l0 55 33 0 c21 0 41 -8 52 -20z"/>
<path d="M3065 2600 c-4 -6 5 -10 20 -10 26 0 26 -2 24 -55 -1 -30 0 -58 4
-62 4 -3 7 21 7 55 0 55 2 62 20 62 11 0 20 5 20 10 0 6 -20 10 -44 10 -25 0
-48 -4 -51 -10z"/>
<path d="M3463 2600 c-39 -16 -26 -56 23 -69 19 -5 29 -14 29 -27 0 -16 -6
-19 -40 -17 -29 2 -36 0 -25 -7 19 -12 60 -13 78 -1 23 14 8 45 -31 64 -49 23
-49 47 1 47 27 0 33 3 22 10 -18 12 -28 12 -57 0z"/>
<path d="M3855 2598 c-2 -7 -5 -39 -7 -71 l-3 -57 45 0 c66 0 72 7 64 72 -5
46 -10 57 -28 62 -35 10 -66 7 -71 -6z m79 -23 c4 -10 -2 -19 -16 -24 -31 -11
-58 -2 -58 20 0 15 7 19 34 19 21 0 36 -6 40 -15z m6 -64 c0 -21 -19 -31 -56
-31 -19 0 -24 5 -24 25 0 23 4 25 40 25 33 0 40 -4 40 -19z"/>
<path d="M1050 2548 c-12 -29 -24 -59 -27 -65 -3 -7 0 -13 6 -13 6 0 11 6 11
14 0 31 74 36 90 5 7 -11 14 -18 18 -15 9 9 -50 126 -64 126 -6 0 -21 -24 -34
-52z m55 2 l16 -30 -31 0 c-16 0 -30 2 -30 5 0 7 23 55 26 55 2 0 10 -13 19
-30z"/>
<path d="M1671 2557 c-2 -66 11 -87 54 -87 43 0 55 21 54 87 l-2 48 -6 -46
c-15 -104 -80 -108 -92 -7 l-6 53 -2 -48z"/>
<path d="M1880 2535 l0 -65 53 2 c50 1 50 2 10 5 l-43 4 0 60 c0 33 -4 59 -10
59 -6 0 -10 -28 -10 -65z"/>
<path d="M2060 2535 l0 -65 53 2 c50 1 50 2 10 5 l-43 4 0 60 c0 33 -4 59 -10
59 -6 0 -10 -28 -10 -65z"/>
<path d="M2246 2539 c-25 -59 -30 -94 -6 -50 15 29 80 30 87 1 3 -11 10 -20
15 -20 6 0 8 6 5 13 -39 92 -52 117 -62 117 -7 0 -24 -28 -39 -61z m64 -11 c0
-5 -11 -8 -25 -8 -27 0 -31 10 -14 42 11 21 12 21 25 -3 7 -13 13 -27 14 -31z"/>
<path d="M2443 2535 c0 -38 2 -55 4 -37 4 31 6 32 50 32 43 0 45 -1 41 -25 -3
-14 -1 -28 3 -31 5 -3 8 25 8 63 -1 37 -4 56 -6 41 -4 -26 -8 -28 -48 -28 -40
0 -44 2 -48 28 -2 15 -4 -4 -4 -43z"/>
<path d="M2886 2539 c-25 -59 -30 -94 -6 -50 15 29 80 30 87 1 3 -11 10 -20
15 -20 6 0 8 6 5 13 -39 92 -52 117 -62 117 -7 0 -24 -28 -39 -61z m64 -11 c0
-5 -11 -8 -25 -8 -27 0 -31 10 -14 42 11 21 12 21 25 -3 7 -13 13 -27 14 -31z"/>
<path d="M3260 2538 c-30 -63 -32 -90 -4 -50 20 30 68 29 88 0 24 -33 19 -6
-8 57 -14 30 -30 55 -36 55 -6 0 -24 -28 -40 -62z m57 12 c11 -29 10 -30 -17
-30 -28 0 -29 1 -19 30 6 17 14 30 18 30 4 0 12 -13 18 -30z"/>
<path d="M3630 2554 c0 -63 13 -84 53 -84 46 0 57 16 56 80 -1 30 -3 44 -6 30
-2 -14 -6 -39 -8 -57 -6 -35 -27 -48 -56 -33 -15 8 -19 22 -19 60 0 28 -4 50
-10 50 -5 0 -10 -21 -10 -46z"/>
<path d="M1316 2332 c-101 -146 -56 -344 90 -392 19 -7 68 -10 107 -8 161 8
285 123 330 304 22 88 27 124 19 124 -5 0 -14 -28 -20 -61 -23 -127 -105 -253
-187 -290 -24 -11 -64 -19 -100 -19 -50 0 -69 5 -103 27 -85 56 -110 184 -58
298 l25 55 -38 0 c-33 0 -42 -5 -65 -38z"/>
<path d="M1950 2363 c0 -4 18 -55 41 -113 22 -58 43 -129 46 -158 9 -84 -11
-108 -112 -131 -29 -6 19 -9 170 -10 139 0 205 3 195 9 -8 5 -21 10 -29 10
-20 0 -69 57 -94 110 -11 25 -41 99 -67 165 l-46 120 -52 3 c-29 2 -52 -1 -52
-5z"/>
<path d="M2810 2288 c0 -46 -5 -123 -10 -173 -10 -101 -27 -132 -81 -150 -36
-13 -24 -14 360 -32 153 -7 215 3 306 49 102 51 166 115 228 225 18 33 57 138
57 155 0 5 -23 8 -52 6 l-51 -3 -17 -62 c-26 -96 -70 -173 -135 -238 -71 -71
-126 -100 -218 -115 -67 -10 -179 -2 -252 18 l-30 8 -3 197 -2 197 -50 0 -50
0 0 -82z"/>
<path d="M2497 1961 c-5 -222 -6 -406 -3 -409 3 -3 8 -3 11 0 3 3 4 187 3 409
l-3 404 -8 -404z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
